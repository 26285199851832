<template>
    <vs-list>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.SIZE')" :subtitle="Room.length + 'm x '+ Room.width +'m | '+Room.height+'m | '+Room.volume+'m³'"></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.AUSSENW')" :subtitle="Room.aussenwand" v-if="Room.aussenwand != ''"  ></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.FENSTER')" :subtitle="Room.fenster" v-if="Room.fenster != ''"  ></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.DECKE')" :subtitle="Room.decke" v-if="Room.decke != ''"  ></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.BODEN')" :subtitle="Room.boden" v-if="Room.boden != ''" ></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.INNENW')" :subtitle="Room.innenwaende" v-if="Room.innenwaende != ''" ></vs-list-item>
        <vs-list-item :title="$t('DEBITORCONTACT.CARD.ROOMS.BSCHR')" :subtitle="Room.description" v-if="Room.description != ''"  ></vs-list-item>
    </vs-list>
</template>
<script>
export default {
  name: "RoomList",
  props:[ 'Room' ],
     data: function (){
     return {
     }
  },

  computed:{

  },
  watch:{
   
  },
  methods:{
    
  }
};
</script>