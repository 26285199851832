<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddObjectDebitor"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ADD_DEBITOROBJECT.HEADER.TEXT')}}</p>
          <vs-tabs v-model="activeTab">
            <vs-tab :label="$t('ADD_DEBITOROBJECT.TAB.NAMES')">
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input ref="name" :label="$t('ADD_DEBITOROBJECT.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.NAME')"  v-model="name"  />               
                <div class="float-left">
                  <div class="vs-component vs-con-input-label vs-input float-left mr-2 inputx vs-input-primary">
                    <div class="vs-con-input">
                      <vs-checkbox v-model="use_baujahr">{{$t('ADD_DEBITOROBJECT.LABEL.BAUJAHR')}}</vs-checkbox>
                      <vs-input-number :disabled="!use_baujahr" v-model="baujahr" :label="$t('ADD_DEBITOROBJECT.LABEL.BAUJAHR')" class="inputx" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <div class="float-left mr-2">
                  <InputSelectList :label="$t('ADD_DEBITOROBJECT.LABEL.OBJECT_TYPE')" :type="DEBITOROBJECTTYPE" v-model="objecttype_txt"></InputSelectList>
                </div>
                <div class="float-left">
                  <InputSelectList :label="$t('ADD_DEBITOROBJECT.LABEL.OBJECT_STRUCTURES')" :type="DEBITOROBJECTSTRUCTURE" v-model="objectstructure_txt"></InputSelectList>
                </div>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <div class="float-left mr-2">
                  <InputSelectList :label="$t('ADD_DEBITOROBJECT.LABEL.OBJECT_NEEDS')" :type="DEBITOROBJECTNEEDS" v-model="objectneeds_txt"></InputSelectList>
                </div>
                <div class="float-left mr-2">
                  <InputSelectList :label="$t('ADD_DEBITOROBJECT.LABEL.OBJECT_PROBLEMS')" :type="DEBITOROBJECTPROBLEMS" v-model="objectproblems_txt"></InputSelectList>
                </div>
                <div class="float-left">
                  <vs-input :label="$t('ADD_DEBITOROBJECT.LABEL.OBJECT_PROBLEMS_NOTE')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.OBJECT_PROBLEMS_NOTE')"  v-model="objectproblem_note"  />
                </div>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_DEBITOROBJECT.LABEL.EXISTING_SYSTEM')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.EXISTING_SYSTEM')"  v-model="extingSystem"  />
                <div class="float-left">
                  <div class="vs-component vs-con-input-label vs-input float-left mr-2 inputx vs-input-primary">
                    <label for="" class="vs-input--label"> </label>
                    <div class="vs-con-input">
                      <vs-checkbox v-model="alshaupt" class="float-left mt-2">{{$t('ADD_DEBITOROBJECT.LABEL.HAUPTHEIZUNG')}}</vs-checkbox>  
                    </div>
                  </div>
                </div>
                
              </div>
              <vs-textarea height="200px" counter="2500" :label="$t('ADD_DEBITOROBJECT.LABEL.BESCHREIBUNG')" :counter-danger.sync="description_fail" v-model="description" />
            </vs-tab>
            <vs-tab :label="$t('ADD_DEBITOROBJECT.RECHNUNGSADRESSE')">
                  <div class="default-input d-flex align-items-center mt-3 mb-3">
                    <vs-checkbox v-model="adrfromdebitor">{{$t('ADD_DEBITOROBJECT.LABEL.ADR_FROM_DEB')}}</vs-checkbox>              
                  </div>
                  <div class="default-input clearfix align-items-center mt-3 mb-3">
                    <vs-input :disabled="adrfromdebitor" ref="street1" :label="$t('ADD_DEBITOROBJECT.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.STREET1')"  v-model="street1" :danger="street1_fail" val-icon-danger="clear"/>
                    <vs-input :disabled="adrfromdebitor" :label="$t('ADD_DEBITOROBJECT.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.STREET2')"  v-model="street2" />
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :disabled="adrfromdebitor" :label="$t('ADD_DEBITOROBJECT.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.PLZ')"  v-model="plz" :danger="plz_fail" val-icon-danger="clear"  :danger-text="$t('ADD_DEBITOROBJECT.PLZ_FAIL')"/>
                    <vs-input :disabled="adrfromdebitor" :label="$t('ADD_DEBITOROBJECT.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.CITY')"  v-model="city" :danger="city_fail" val-icon-danger="clear"/>
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-select :disabled="adrfromdebitor" :label="$t('ADD_DEBITOROBJECT.LABEL.COUNTRY')" v-model="country_fk" class="float-left" :danger="country_fk_fail">
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                    </vs-select>
                  </div>
            </vs-tab>
            <vs-tab :label="this.$t('ADD_DEBITOROBJECT.LIEFERADRESSE')">  
                  <div class="default-input d-flex align-items-center mt-3 mb-3">
                    <vs-checkbox v-model="extraAddress">{{$t('ADD_DEBITOROBJECT.LABEL.EXTRA_ADDR')}}</vs-checkbox>              
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :disabled="!extraAddress" ref="street1_del" :label="$t('ADD_DEBITOROBJECT.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.STREET1')"  v-model="street1_del" :danger="street1_del_fail" val-icon-danger="clear"/>
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOROBJECT.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.STREET2')"  v-model="street2_del" />
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOROBJECT.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.PLZ')"  v-model="plz_del" :danger="plz_del_fail" val-icon-danger="clear"  :danger-text="$t('ADD_DEBITOROBJECT.PLZ_FAIL')" />
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOROBJECT.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOROBJECT.LABEL.PLACEHOLDER.CITY')"  v-model="city_del" :danger="city_del_fail" val-icon-danger="clear"/>
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-select :disabled="!extraAddress" :label="$t('ADD_DEBITOROBJECT.LABEL.COUNTRY')" v-model="country_fk_del" class="float-left"  :danger="country_fk_del_fail">
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                    </vs-select>
                  </div> 
            </vs-tab>   
          </vs-tabs>
        </div>
        <div class="d-flex">
          <vs-button v-if="activeTab > 0 " class="mr-3" type="filled" icon="arrow_back_ios" @click="back()">{{$t('COM.BACK')}}</vs-button>
          <vs-button v-if="activeTab < idLastTab" type="filled" icon="arrow_forward_ios" @click="next()">{{$t('COM.NEXT')}}</vs-button>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import InputSelectList from '../InputSelectList.vue';
import {inputSelectType} from '../../../helper/enumHelper';

export default {
  name: "AddDebitorObjectDlg",
  props:['InitData'],
  components:{InputSelectList},
  data: function (){
     return {
       id:0,
       dlgActive: false,
       activeTab: 0,
       idLastTab: 2,
       popupTitle: this.$t('ADD_DEBITOROBJECT.TITLE'),
       name:"",
       name_fail:false,
       name_ok:false,
       description:"",
       description_fail:false,
       
       DEBITOROBJECTNEEDS:inputSelectType.DEBITOROBJECTNEEDS,
       DEBITOROBJECTSTRUCTURE:inputSelectType.DEBITOROBJECTSTRUCTURE,
       DEBITOROBJECTTYPE: inputSelectType.DEBITOROBJECTTYPE,
       DEBITOROBJECTPROBLEMS: inputSelectType.DEBITOROBJECTPROBLEMS,

       objecttype_txt:"",
       objectneeds_txt:"",
       objectstructure_txt:"",
       objectproblems_txt:"",
       objectproblem_note:"",
       alshaupt:false,
       extingSystem:"",
       baujahr:"1960",
       use_baujahr:true,

       debitor_fk:this.DebitorFk,
       street1:"",
       street1_fail:false,
       street1_ok:false,
       street2:"",
       plz:"",
       plz_fail:false,
       plz_ok:false,
       city:"",
       city_fail:false,
       city_ok:false,
       country_fk:-1,
       country_fk_fail:false,
       country_fk_ok:false,
       street1_del:"",
       street1_del_fail:false,
       street1_del_ok:false,
       street2_del:"",
       plz_del:"",
       plz_del_fail:false,
       plz_del_ok:false,
       city_del:"",
       city_del_fail:false,
       city_del_ok:false,
       country_fk_del:-1,   
       country_fk_del_fail:false,
       country_fk_del_ok:false,
       extraAddress:false,
       address_fk:0,
       address2_fk:0,
       adrfromdebitor:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      next(){
        this.activeTab += 1;
      },
      back(){
        this.activeTab -= 1;
      },
      showDlg(id){
        this.ClearForm();
        this.debitor_fk = id;
        this.dlgActive = true;
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {

          this.id = data.debitorobject.id;
          this.name = data.debitorobject.name;
          this.description = data.debitorobject.description;
          this.debitor_fk = data.debitorobject.debitor_fk;
          this.objecttype_txt=data.debitorobject.objecttype_txt;
          this.objectneeds_txt = data.debitorobject.objectneeds_txt;
          this.objectstructure_txt = data.debitorobject.objectstructure_txt;
          this.objectproblems_txt = data.debitorobject.objectproblems_txt;
          this.objectproblem_note = data.debitorobject.objectproblems_note;
          this.alshaupt = data.debitorobject.alsHauptheizung;
          this.extingSystem = data.debitorobject.existing_heating_system;
          this.use_baujahr = Number(data.debitorobject.baujahr) > 0;
          this.baujahr = data.debitorobject.baujahr;

          this.street1 = data.address1.street1;
          this.street2 = data.address1.street2;
          this.plz = data.address1.plz;
          this.city = data.address1.city;
          this.country_fk = data.address1.country_fk;  
          
          this.address_fk = data.address1.id;
          this.adrfromdebitor = data.adrfromdebitor;          

          if(this.IsNotNull(data.address2))
          {
            if(this.IsNotNull(data.address2.street1))
            {
              if(data.address2.street1.length > 0)
              {
                this.extraAddress = true;
                this.street1_del = data.address2.street1;
                this.street2_del = data.address2.street2;
                this.plz_del = data.address2.plz;
                this.city_del = data.address2.city;
                this.country_fk_del = data.address2.country_fk;

                this.address2_fk = data.address2.id;
              }
            }
          }
          
          this.popupTitle = this.$t('ADD_DEBITOROBJECT.TITLE.EDIT');

          this.dlgActive=true;
        }
      },
      accepted(){

          var data = {
            debitorobject:{
              "id":this.id,
              "debitor_fk":this.debitor_fk,
              "name":this.name,
              "description":this.description,
              "address_fk":this.address_fk,
              "address2_fk": this.address2_fk,
              "objecttype_txt": this.objecttype_txt,
              "objectneeds_txt":this.objectneeds_txt,
              "objectstructure_txt":this.objectstructure_txt,
              "objectproblems_txt":this.objectproblems_txt,
              "objectproblems_note":this.objectproblem_note,
              "alsHauptheizung":this.alshaupt,
              "existing_heating_system":this.extingSystem,
              "baujahr":Number(this.baujahr)
              },
            address1:
              {
                "street1":this.street1,
                "street2":this.street2,
                "plz":this.plz,
                "city":this.city,
                "country_fk":this.country_fk
              },
              address2:
              {
                "street1":this.street1_del,
                "street2":this.street2_del,
                "plz":this.plz_del,
                "city":this.city_del,
                "country_fk":this.country_fk_del
              },
              adrfromdebitor:this.adrfromdebitor
          };

          var name = this.name;
          var deb_id = this.debitor_fk;
          this.$store.dispatch('debitorobject/saveDebitorObject', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('debitorobject/getDebitorObjectsMin', deb_id); 
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_DEBITOROBJECT.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('ADD_DEBITOROBJECT.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted',response.id);
          }, 
          error =>{
              var text = this.$t('ADD_DEBITOROBJECT.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted',0);
          });
          
          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      isNameFail(name,isMustHave){
        var fail = false;
        if(isMustHave)
        {
          if(name.length > 0)
          {
              if(!this.ContainNoNumbers(name))
              {
                // Name enthält Zahlen
                fail = true;
              }
          }
          else
          {
            fail = true;
          }
        }
        else
        {
         fail = !this.ContainNoNumbers(name);
        }
        return fail;
      },
      ClearForm(){
        this.id = 0;
        this.activeTab =  0;
        this.name = "";
        this.name_fail = false;
        this.name_ok = false;
        this.description = "";
        this.objecttype_txt = "";
        this.objectneeds_txt = "";
        this.objectstructure_txt = "";
        this.objectproblems_txt = "";
        this.objectproblem_note = "";
        this.alshaupt = false;
        this.extingSystem = "";
        this.baujahr = "1960";

        this.street1 = "";
        this.street1_fail = false;
        this.street1_ok = false;
        this.street2 = "";
        this.plz = "";
        this.plz_fail = false;
        this.plz_ok = false;
        this.city = "";
        this.city_fail = false;
        this.city_ok = false;
        this.country_fk = -1;
        this.country_fk_fail = false;
        this.country_fk_ok = true;// weil vorher schon ausgewählt
        this.street1_del = "";
        this.street1_del_fail = false;
        this.street1_del_ok = false;
        this.street2_del = "";
        this.plz_del = "";
        this.plz_del_fail = false;
        this.plz_del_ok = false;
        this.city_del = "";
        this.city_del_fail = false;
        this.city_del_ok = false;
        this.country_fk_del = -1;
        this.country_fk_del_fail = false;
        this.country_fk_del_ok = true;
        this.extraAddress = false;
        this.address_fk = 0;
        this.address2_fk = 0;
        this.adrfromdebitor = false;

        if(this.countries.length > 1 )
        {
          this.country_fk = this.countries[1].value;
          this.country_fk_del = this.countries[1].value;
        }

        this.popupTitle = this.$t('ADD_DEBITOROBJECT.TITLE');
      },
      setAddr1(response)
      {
        this.street1 = response.street1; 
        this.street2 = response.street2;
        this.plz = response.plz;
        this.country_fk = response.country_fk;
        this.city = response.city; 
      }
  },
  computed:{
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    formValid(){
        var valid = this.name_ok && !this.description_fail && this.street1_ok && this.plz_ok && this.city_ok && this.country_fk_ok;

        if(this.extraAddress == true)
        {
          valid = valid && this.street1_del_ok && this.plz_del_ok && this.city_del_ok && this.country_fk_del_ok;
        }

        return valid;
    },
  },
  watch:{
    activeTab(value){
      switch(value)
      {
        case 0:
          this.$nextTick(() => this.setFocusOnInput('name'));
          break;
        case 1:
          this.$nextTick(() => this.setFocusOnInput('street1'));
          break;
        case 2:
          this.$nextTick(() => this.setFocusOnInput('street1_del'));
          break;          
      }
    },
    use_baujahr(value)
    {
      if(value == false)
      {
        this.baujahr = 0;
      }
      else
      {
        this.baujahr = 1960;
      }
    },
    name(value)
    {
      this.name_fail = this.isNameFail(value,true);
      this.name_ok = !this.name_fail;
    },
    street1(value){
      this.street1_fail = value.length == 0;
      this.street1_ok = !this.street1_fail;
    },
    plz(value){
      this.plz_fail = value.length == 0 || !this.ContainNumbersOnly(value);
      this.plz_ok = !this.plz_fail;
    },
    city(value){
      this.city_fail = value.length == 0;
      this.city_ok = !this.city_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    street1_del(value){
      
      if(this.extraAddress == true)
      {
        this.street1_del_fail = value.length == 0;
        this.street1_del_ok = !this.street1_del_fail;
      }   
    },
    plz_del(value){
      
      if(this.extraAddress == true)
      {
        this.plz_del_fail = value.length == 0 || !this.ContainNumbersOnly(value);
        this.plz_del_ok = !this.plz_del_fail;
      }
    },
    city_del(value){
      if(this.extraAddress == true)
      {
        this.city_del_fail = value.length == 0;
        this.city_del_ok = !this.city_del_fail;
      } 
    },
    country_fk_del(value){
      if(this.extraAddress == true)
      {
        this.country_fk_del_fail = value <= 0;
        this.country_fk_del_ok = !this.country_fk_del_fail;
      }
    },
    adrfromdebitor(value)
    {
      if(value == true)
      {
        // Nach Debitor suchen

        var data = this.$store.state.debitor.debitors.data;

        if(data != null)
        {
          for(var i = 0; i < data.length; i++)
          {
            if(data[i].debitor.id == this.debitor_fk)
            {
              this.setAddr1(data[i].rech_adr);
              break;
            }
          }
        }
      }
    },
    
    extraAddress(value)
    {
      if(value == false)
      {
        this.city_del_fail = false;
        this.city_del_ok = false;
        this.city_del = "";

        this.plz_del_fail = false;
        this.plz_del_ok = false;
        this.plz_del = "";

        this.street1_del_fail = false;
        this.street1_del_ok = false;
        this.street1_del = "";

        this.street2_del ="";
        this.country_fk_del = -1;
        this.country_fk_del_fail = false;
        this.country_fk_del_ok = false;

        if(this.address2_fk > 0)
        {
          this.$store.dispatch('debitorobject/deleteLieferAdr', this.address2_fk)
            .then(response => {
              if(response.success === true)
              {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_DEBITOROBJECT.SUCCESS.DELETE_LIEF')});  
              }
              else
              {
                var text = this.$t('ADD_DEBITOROBJECT.WARNING.DELETE_LIEF').replace("*1*",this.$t(response.text));
                this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
              }
              this.address2_fk = 0;
            }, 
            error =>{
                var text = this.$t('ADD_DEBITOROBJECT.ERROR.DELETE_LIEF').replace("*1*",error);
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
            });
        }
      }
      else
      {
        this.$nextTick(() => this.setFocusOnInput('street1_del'));
      }
    }
  }
};
</script>
<style>
div#promptAddObjectDebitor > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddObjectDebitor > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>