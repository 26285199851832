<template>
    <div>
      <vs-button v-if="!hidden && !withText" @click="BtnClicked" size="small" color="gray" type="filled" icon="mail"></vs-button> 
      <vs-button v-if="withText" @click="BtnClicked" color="gray" type="filled" icon="mail">{{btnText}}</vs-button> 
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SEND')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptSendMailDlg"
      :is-valid="formValid">
       <div>
        <div v-if="noDoc == false">
          <div class="default-input d-flex align-items-center">
            <vs-button v-if="mailSentAlready" v-show="!alert_active" size = "small" icon="warning" @click="alert_active = true"  class="mb-2">{{$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_BTN_TXT')}}</vs-button>
          </div>
          <vs-alert
            :active.sync="alert_active"
            closable
            close-icon="clear"
            :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TITLE')"
            icon ="warning" class="mb-2">
            <p>{{alertText}}</p>
              
              <p v-show="mail1Sent.length > 0"><vs-icon  size="15px" icon="mail"></vs-icon> {{mail1Sent}}<br/>
              <span v-show="mail2Sent.length > 0"><vs-icon  size="15px" icon="mail"></vs-icon> {{mail2Sent}}</span></p>
              <p><span v-show="mail_internalSent.length > 0"><vs-icon  size="15px" icon="mail"></vs-icon> {{mail_internalSent}} (intern)</span></p>

            </vs-alert>

          <p>{{$t('MESSAGES.SEND_DOCUMENT_MAIL.TEXT')}}</p>
           <vs-progress v-show="firstname.length == 0" indeterminate color="primary"></vs-progress>

          <div class="default-input d-flex align-items-center">
            <vs-list v-show="firstname.length > 0 && this.type != this.docTypeDelivery" >
              <vs-list-header :title="firstname + ' ' + lastname + ', ' + tel1"></vs-list-header>
              <vs-list-item v-show="mail1.length > 0" :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.MAIL')" :subtitle="mail1"><vs-checkbox  v-model="use_mail1"></vs-checkbox></vs-list-item>
              <vs-list-item v-show="mail2.length > 0" :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.MAIL2')" :subtitle="mail2"><vs-checkbox  v-model="use_mail2"></vs-checkbox></vs-list-item>
            </vs-list>
          </div>
          <div class="default-input d-flex align-items-center">
            <vs-list v-show="mail_internal.length > 0">
              <vs-list-header :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.MAIL_INTERNAL_TITEL')"></vs-list-header>
              <vs-list-item v-show="mail_internal.length > 0" :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.MAIL_INTERN')" :subtitle="mail_internal"><vs-checkbox  v-model="use_mail_internal"></vs-checkbox></vs-list-item>
            </vs-list>
          </div>          
        </div>
        <div v-if="noDoc == true">
          <vs-alert
            :active.sync="noDoc"
            close-icon="clear"
            :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TITLE_NO_DOC')"
            icon ="warning" class="mb-2">
            <vs-progress indeterminate class="mt-3 mb-3" color="primary"></vs-progress>
            <p>{{$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TEXT_NO_DOC')}}</p>
              <vs-button v-if="!printStarted" icon="print" @click="PrintDoc()"  class="mb-2">{{$t('MESSAGES.SEND_DOCUMENT_MAIL.PRINT')}}</vs-button>
              <vs-button v-if="printStarted" color="gray" icon="refresh" @click="LoadEmails()"  class="mb-2">{{$t('MESSAGES.SEND_DOCUMENT_MAIL.RELOAD')}}</vs-button>
            </vs-alert>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import {documentType} from "../../../helper/enumHelper";

export default {
  name: "SendDebitorDocuemntMailBtn",
  props:['doc_nr','doc_id','doc_type','HideButton', 'ButtonText', 'WithText'],
  data: function (){
     return {
       dlgActive:false,
       popupTitle:this.$t('MESSAGES.SEND_DOCUMENT_MAIL.TITLE'),
       mail1:"",
       mail2:"",
       mail_internal:"",
       use_mail1:false,
       use_mail2:false,
       use_mail_internal:false,
       linked_fk:0,
       type: 0,
       placeholder:"",
       firstname: "",
       lastname:"",
       tel1:"",
       alert_active:false,
       mailInfos: null,
       hidden:false,
       btnText:this.ButtonText,
       withText: this.WithText,
       noDoc:false,
       printStarted:false,
       docTypeDelivery:documentType.DELIVERY,
     }
  }, 
    created () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Listen to score changes coming from SignalR events
      this.$sharedHub.$on('documentready', this.DocumentReady);
    }
  },
  beforeDestroy () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Make sure to cleanup SignalR event handlers when removing the component
      this.$sharedHub.$off('documentready', this.DocumentReady);
    }
  },
  mounted () {
    if(this.IsNotNull(this.HideButton))
    {
      this.hidden = true;
    }
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      BtnClicked(){
        this.ClearForm();
        this.linked_fk = this.doc_id;
        this.type = this.doc_type;
        this.placeholder = this.doc_nr;

        this.dlgActive=true;
        this.LoadEmails();
      },
      ShowDlg(doc_id,doc_type,doc_nr){
        this.ClearForm();
        this.linked_fk = doc_id;
        this.type = doc_type;
        this.placeholder = doc_nr;

        this.dlgActive=true;
        this.LoadEmails();
      },
      DocumentReady()
      {
        if(this.noDoc == true && this.printStarted == true)
        {
          this.LoadEmails();
        }
      },
      LoadEmails(){
        
          this.$store.dispatch('debitor/getDebitorForDocument', {linked_fk:this.linked_fk,type:this.type})
          .then(response => {
            if(this.IsNotNull(response))
            {
              this.noDoc = false;
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MESSAGES.SUCCESS.GET_DEB_BY_DOC')}); 

              if(response.debitor == null)
              {
                this.noDoc = true;
                if(this.printStarted == false)
                {
                  this.PrintDoc();
                }
              }
              else
              {
                if(!this.IsTextEmpty(response.debitor.email))
                {
                  this.mail1 = response.debitor.email;
                }

                if(!this.IsTextEmpty(response.debitor.email2))
                {
                  this.mail2 = response.debitor.email2;
                }

                if(!this.IsTextEmpty(response.debitor.firstname))
                {
                  this.firstname = response.debitor.firstname;
                }

                if(!this.IsTextEmpty(response.debitor.lastname))
                {
                  this.lastname = response.debitor.lastname;
                }
                
                if(!this.IsTextEmpty(response.debitor.tel1))
                {
                  this.tel1 = response.debitor.tel1;
                }   

                if(!this.IsTextEmpty(response.mail_internal))
                {
                  this.mail_internal = response.mail_internal;
                }

                this.mailInfos = response.mailinfos;
              }
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MESSAGES.ERROR.GET_DEB_BY_DOC')}); 
            }
          }, 
          error =>{

              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MESSAGES.ERROR.GET_DEB_BY_DOC').replace("*1*",error)});  
          });
      },
      PrintDoc(){
        this.$store.dispatch('document/createDocument', {id:this.linked_fk,name:this.placeholder, type: this.type})
              .then(success => {
                if(success === true)
                {
                  this.printStarted = true;
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
      },
      closed(){
        this.ClearForm();
      },
      accepted(){
          
          let data = {linked_fk: this.linked_fk, type: this.type,mail1:this.use_mail1,mail2:this.use_mail2,mail_internal:this.use_mail_internal};
          if(this.type == this.docTypeDelivery)
          {
            data = {linked_fk: this.linked_fk, type: this.type,mail1:false,mail2:false,mail_internal:this.use_mail_internal};
          }

          this.$store.dispatch('accounting/sendDocumentMail', data)
          .then(response => {

            if(response.success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MESSAGES.SUCCESS.SEND_MAIL').replace("*1*",this.placeholder)});
            }
            else
            {
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('MESSAGES.WARNING.SEND_MAIL').replace("*1*",this.placeholder).replace("*2*",response.text)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MESSAGES.ERROR.SEND_MAIL').replace("*1*",this.placeholder)+' '+error});
            this.ClearForm();
          }); 
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.linked_fk =0;
        this.type = 0;
        this.use_mail1= false;
        this.use_mail2 = false;
        this.use_mail_internal = false;
        this.mail1 = "";
        this.mail2 = "";
        this.mail_internal = "";
        this.placeholder = "";
        this.firstname = "";
        this.lastname = "";
        this.tel1 = "";
        this.mailInfos = null;
        this.printStarted = false;
        this.noDoc = false;
      }
  },
  computed:{
    formValid(){
      return this.use_mail1 || this.use_mail2 || this.use_mail_internal;
    },
    alertText(){
      var text = this.$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TEXT');
      text = text.replace("*1*",this.documentnameSent);
      text = text.replace("*2*",this.documentnameSentBy);
      text = text.replace("*3*",this.documentnameSentAt);
      return text;
    },
    mailSentAlready(){
      var sent = false;

      if(this.IsNotNull(this.mailInfos))
      {
        sent = true;
      }

      return sent;
    },
    documentnameSent(){
      var name = "";
      if(this.IsNotNull(this.mailInfos))
      {
        name = this.mailInfos.linked_nr;
      }
      return name;
    },
    mail1Sent(){
      var name = "max@muster.at";
      if(this.IsNotNull(this.mailInfos) && this.IsEmpty(this.mailInfos) == false)
      {
        name = "";
        if(this.mailInfos.sent_to_mail2.length > 0)
        {
          name = this.mailInfos.sent_to_mail2;
        }
        if(this.mailInfos.sent_to_mail1.length > 0)
        {
          name = this.mailInfos.sent_to_mail1;
        }
      }
      return name;
    },
    mail2Sent(){
      var name = "max@muster.at";
      if(this.IsNotNull(this.mailInfos) && this.IsEmpty(this.mailInfos) == false)
      {
        name = this.mailInfos.sent_to_mail2;
      }
      return name;
    },
    mail_internalSent(){
      var name = "max@muster.at";
      if(this.IsNotNull(this.mailInfos) && this.IsEmpty(this.mailInfos) == false)
      {
        name = this.mailInfos.sent_to_mail_internal;
      }
      return name;
    },
    documentnameSentBy(){
      var name = "";
      if(this.IsNotNull(this.mailInfos))
      {
        name = this.mailInfos.sent_by_username;
      }
      return name;
    },
    documentnameSentAt(){
      var name = "";
      if(this.IsNotNull(this.mailInfos))
      {
        name = this.DateToString(this.mailInfos.sent_at);
      }
      return name;
    }
  },
  watch:{
    mail1(value)
    {
      if(!this.IsTextEmpty(value))
      {
        this.use_mail1 = true;
      }
    },
    mail2(value)
    {
      if(!this.IsTextEmpty(value))
      {
        this.use_mail2 = true;
      }
    },
    mail_internal(value)
    {
      if(!this.IsTextEmpty(value))
      {
        this.use_mail_internal = true;
      }
    },    
    mailSentAlready(value)
    {
      if(value == true)
      {
        this.alert_active = true;
      }
    }
  }
};
</script>
<style>
div.vs-alert--close{
  padding:1px;
}
div#promptSendMailDlg{
  z-index:39999 !important;
}
</style>