<template>

  <iframe ref="pdfviewerFrame" height="98%" width=100% :src="getFilePath" ></iframe>

</template>

<script>
// Mehr infos unter
// https://pdfjs.express/blog/how-build-pdf-viewer-vuejs-pdfjs

import codeHelper from '../../helper/staticCodeHelper';
import downloadHelper from '../../helper/staticDownloadFuncHelper';

export default {
  name: "PdfJsViewer",
     components: {     
  },
  props:['fileName'],
  data: function (){
     return {

     }
  },
  computed:{
    getFilePath()
    {
        var retVal = "";
        var path = "../../../../lib/pdfjs/web/viewer.html";
        if(this.IsNotNull(this.fileName) && this.IsNotNull(path))
        {
            if(this.fileName!==''){
                retVal = path +'?file=' + this.fileName;
            }
            else
            {
                retVal = path;
            }
        }
        return retVal;
    }
  },
  watch:{
    
  },
  methods:{
    ...codeHelper,
    ...downloadHelper,
    LoadPdfBlobToViewer(data){
        this.$refs.pdfviewerFrame.contentWindow.PDFViewerApplication.open(this.ResponseToArray(data));
    }
  }
};
</script>