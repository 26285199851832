<template>
  <div>
    <div class="d-flex">
      <vs-input v-show="useProz" v-model="prozent" type="number" class="mr-1" :label="$t('COM.PROZENT')"></vs-input>
      <vs-input v-show="!useProz" v-model="betrag" type="number" class="mr-1" :label="$t('COM.BETRAG')"></vs-input>
      <vs-tooltip :text="$t('COM.SWITCH_VIEW')"><vs-button icon="shuffle" type="filled" @click="useProz = !useProz" class=" btn-33"></vs-button></vs-tooltip>
        <ButtonList BtnClass="btn-drop ml-1 btn-33" BtnSize="medium" :buttonArray="[ {label:'25%',color:'primary',type:'filled',icon:'',show:true, disabled:false,data:[25]},
                                    {label:'50%',color:'primary',type:'filled',icon:'',show:true,disabled:false,data:[50]},
                                    {label:'75%',color:'primary',type:'filled',icon:'',show:true,disabled:false,data:[75]}
                                  ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
        
    </div>
    <div class="d-flex mt-2">
      <span v-show="useProz">{{GetBetragsText()}}</span>
      <span v-show="!useProz">{{GetProzentText()}}</span>
    </div>
  </div>
</template>
<script>
import helperMethods from '../../helper/staticFuncHelper';
import ButtonList from './ButtonList.vue';

export default {
  name: "ValueProzent",
  props:['CurrencyKuerzel', 'LanguageFk', 'FullAmount','InitProz','InitAmount'],
  components:{ButtonList},
   data: function (){
     return {
       useProz:true,
       prozent:this.InitProz,
       betrag:this.InitAmount
     }
  },
  computed:{
    
  },
  methods:{
    ...helperMethods,
    ButtonListClicked(index, data){
      this.prozent = Number(data[0]);
    },
    GetProzentText(){
      return this.$t('COM.VALUE_PROZ_TEXT1').replace("*1*",this.prozent.toFixed(2)).replace("*2*",this.GetCurrencyString(this.FullAmount));
    },
    GetBetragsText(){
      return this.$t('COM.VALUE_PROZ_TEXT2').replace("*1*",this.GetCurrencyString(this.betrag)).replace("*2*",this.GetCurrencyString(this.FullAmount));
    },
    GetCurrencyString(amount)
    {
      var retVal = "";
      if(this.CurrencyKuerzel.length > 0 && this.LanguageFk != 0)
      {
        retVal = this.DecimalToString(amount,this.CurrencyKuerzel,this.GetLocalFormatOfLanguage(this.LanguageFk));  
      }
      return retVal;
    },
    ValuesChanged()
    {
      this.$emit('ValuesChanged',{amount:this.betrag,proz:this.prozent});
    },
    SetAmount(amount){
      this.betrag = amount;
    },
    SetProzent(proz){
      this.prozent = proz;
    }
  },
  watch:{
    prozent(value)
    {
      var proz = Number(value);
      this.betrag = this.FullAmount * proz / 100;
      this.ValuesChanged();
    },
    betrag(value)
    {
      if(value > this.FullAmount)
      {
        value = this.FullAmount;
      }
      if(value < 0)
      {
        value = 0;
      }
      this.prozent = 100 * value / this.FullAmount;
      this.ValuesChanged();
    }
  }
};
</script>
<style>
.btn-33{
margin-top:25px;
height:33px !important;
width:33px !important
}
</style>
