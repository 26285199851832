<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddInvoiceReminder"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.HEADER.TEXT')}}</p>         
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('INVOICE_REMINDER.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('INVOICE_REMINDER.LABEL.PLACEHOLDER.BEZ')"  v-model="data.bez"  :danger="bez_fail" val-icon-danger="clear" />  
          </div>      
          <DatePicker ref="pickerDueDate" Type="date" class="float-left" :Label="$t('INVOICE_REMINDER.ADD.DUE_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('INVOICE_REMINDER.ADD.TITLE.DUE_DATE')" v-model="data.paymentDueDate"></DatePicker>    
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.spesen" min="0" :label="$t('INVOICE_REMINDER.ADD.LABEL.SPESEN')" class="inputx" />
            </div>
            <div  class="float-left mr-2">
              <ValueProzent ref="verzugProzVal" class="mt-3" :CurrencyKuerzel="invoice.currency_kuerzel" :LanguageFk="invoice.language_fk" :FullAmount="invoice.amount" v-on:ValuesChanged="ValuesChanged" :InitAmount="data.verzug_value" :InitProz="data.verzug_percent"></ValueProzent>

            </div>
          </div>   
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';
import ValueProzent from '../ValueProzent.vue';

export default {
  name: "AddInvoiceReminderDlg",
  props:[],
  components:{
    DatePicker,
    ValueProzent
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.TITLE'),
      invoice:null,
      data:this.GetEmptyData(),
      bez_fail:false,
      bez_ok: false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(invoice){

        this.invoice = invoice;
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.TITLE');
        
      },
      InitDlgData(invoice, data){

        if(this.IsNotNull(data))
        {
          this.invoice = invoice;
          this.ClearForm();
          this.popupTitle = this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
          this.$refs.verzugProzVal.SetProzent(data.verzug_percent);
        }
      },      
      closed(){
        this.ClearForm();
      },
      accepted(){

          this.data.invoice_fk = this.invoice.id;
          this.$store.dispatch('accounting/saveInvoiceReminder', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE_REMINDER.ADD_INVOICE_REMINDER.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
        this.bez_fail = false;
        this.bez_ok = false;
      },
      ValuesChanged(data)
      {
        this.data.verzug_value = data.amount;
        this.data.verzug_percent = data.proz;
      },
      GetEmptyData(){
        return {
          id:0,
          invoice_fk:0,
          paymentDueDate:null,
          spesen:0,
          verzug_percent:5,
          verzug_value:0,
          amount:0,
          bez:""
        };
      }
  },
  computed:{
    formValid(){
      return this.bez_ok;
    },
    bez()
    {
      let txt = "";
      if(this.IsNotNull(this.data))
      {
        txt = this.data.bez;
      }
      return txt;
    }
  },
  watch:{
        bez(value){

        this.bez_fail = value.length == 0;
        this.bez_ok = !this.bez_fail;
    }
  }
};
</script>
<style>
div#promptAddInvoiceReminder > div.vs-dialog{
  max-width: 500px !important;
}
div#promptAddInvoiceReminder > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>