<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPayment"
      :is-valid="formValid">
       <div>
        <div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-chip > <vs-avatar :icon="amount >= 0 ? 'arrow_back' : 'arrow_forward'" :color="amount >= 0 ? 'green' : 'red'"/> {{$t(paymentTypeText)}} </vs-chip>
          </div>
          <p>{{$t('PAYMENT.ADD_PAYMENT.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('PAYMENT.ADD.LABEL.REF')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.REF')" v-model="reference"  :danger="reference_fail" val-icon-danger="clear"/>
            <vs-input :label="$t('PAYMENT.ADD.LABEL.PAYED_BY')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.PAYED_BY')" v-model="payed_by_name"/>
            <vs-input type="number" :label="$t('PAYMENT.ADD.LABEL.AMOUNT')"  class="float-left mr-2 inputx" :placeholder="0" v-model="amount" :danger="amount_fail" val-icon-danger="clear"/>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('PAYMENT.ADD.LABEL.IBAN')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.IBAN')" v-model="iban"/>
            <vs-input :label="$t('PAYMENT.ADD.LABEL.BIC')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.BIC')" v-model="bic"/>
            <vs-input :label="$t('PAYMENT.ADD.LABEL.BANKNAME')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENT.ADD.LABEL.PLACEHOLDER.BANKNAME')" v-model="bankname"/>
          </div> 
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
            <vs-select :label="$t('PAYMENT.ADD.LABEL.COUNTRY')" v-model="country_fk" :danger="country_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
            </vs-select>
            </div>
            <div  class="float-left mr-2">
            <vs-select :label="$t('PAYMENT.ADD.LABEL.WAEHRUNG')" v-model="currency_fk" :danger="currency_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
            </vs-select>
            </div>
            <DatePicker  class="float-left" :Label="$t('PAYMENT.ADD.LABEL.DATE_PAYED')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('PAYMENT.ADD.POPUP.TITLE.DATE_PAYED')" v-model="date_payment"></DatePicker>
          </div>    
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';
import {paymentType} from '../../../helper/enumHelper';

export default {
  name: "AddPaymentDlg",
  props:[],
  components:{
    DatePicker
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('PAYMENT.ADD_PAYMENT.TITLE'),
      amount:0,
      amount_fail:false,
      amount_ok:false,
      date_fail:false,
      reference_fail:false,
      reference_ok:false,
      debitor_fk:0,
      reference:"",
      bankname:"",
      bic:"",
      iban:"",
      date_payment:null,
      payed_by_name:"",
      country_fk:-1,
      currency_fk:-1,
      currency_fk_fail:false,
      currency_fk_ok:false,
      country_fk_fail:false,
      country_fk_ok:false, 
      editmode:false,
      id:0     
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(data){
        this.ClearForm();
        this.dlgActive=true;
        this.date_fail = true;
        this.editmode = false;
        this.popupTitle = this.$t('PAYMENT.ADD_PAYMENT.TITLE');
        if(this.IsNotNull(data))
        {
          this.amount = this.Round(data.amount);
          this.reference = data.reference;
        }
        this.date_payment = this.GetDateTimeNow();
        this.SelectFirstCountryCurrency();
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.SelectFirstCountryCurrency();
          this.popupTitle = this.$t('PAYMENT.ADD_PAYMENT.TITLE.EDIT');
          this.amount = this.Round(data.amount);
          this.reference = data.reference;
          this.date_payment = data.date_payment;
          this.payed_by_name = data.payed_by_name;
          this.iban = data.iban;
          this.bic = data.bic;
          this.id = data.id;
          this.currency_fk = data.currency_fk;
          this.country_fk = data.country_fk;
          this.company_fk = data.company_fk;
          this.created_by_user = data.created_by_user;
          this.language_fk = data.language_fk;
          this.currency_kuerzel = data.currency_kuerzel;
          this.created = data.created;
          this.editmode = true;
          this.dlgActive=true;
        }
      },
      accepted(){

          var payment = {
            amount: this.amount,
            reference: this.reference,
            bankname: this.bankname,
            bic: this.bic,
            iban: this.iban,
            date_payment: this.date_payment,
            payed_by_name:this.payed_by_name,
            id:this.id,
            currency_fk: this.currency_fk,
            country_fk:this.country_fk,
            company_fk: this.company_fk,
            created_by_user: this.created_by_user,
            language_fk: this.language_fk,
            currency_kuerzel: this.currency_kuerzel,
            created: this.created
          };

          this.$store.dispatch('payment/savePayment', payment)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PAYMENT.ADD_PAYMENT.SUCCESS.SAVE')});  
                if(this.editmode == false)
                {
                  this.GetInvoicesForPayment(response.id);
                }
                
                this.$emit('Success',response.id);
            }
            else
            {
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PAYMENT.ADD_PAYMENT.WARNING.SAVE').replace("*1*",response.text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENT.ADD_PAYMENT.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.amount = 0;
        this.debitor_fk = 0;
        this.reference = "";
        this.bankname = "";
        this.bic = "";
        this.iban = "";
        this.date_payment = null;
        this.payed_by_name = "";
        this.amount_fail = false;
        this.amount_ok = false;
        this.date_fail = false;
        this.reference_fail = false;
        this.reference_ok = false;
        this.country_fk_fail = false;
        this.country_fk_ok = false;
        this.currency_fk_fail = false;
        this.currency_fk_ok = false;
        this.currency_fk = -1;
        this.country_fk = -1;
        this.id = 0;
      },
      SelectFirstCountryCurrency(){
        if(this.IsNotNull(this.countries))
        {
          if(this.countries.length > 1)
          {
            this.country_fk = this.countries[1].value;
          }
        }
        if(this.IsNotNull(this.currencies))
        {
          if(this.currencies.length > 1)
          {
            this.currency_fk = this.currencies[1].value;
          }
        }
      },
      GetInvoicesForPayment(id)
      {
        this.$store.dispatch('accounting/getInvoicesForPaymentById',id)
        .then(response => {

            if(this.IsNotNull(response))
            {
              if(response.length == 1)
              {
                // genau EINE passende Rechnung gefunden
                this.ShowConnectPaymentDlg(response[0]);
              }
              else
              {
                this.ShowNoInvoiceFoundDlg();
              }
            }
            else
            {
              this.ShowNoInvoiceFoundDlg();
            }
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENT.ADD_PAYMENT.ERROR.SAVE').replace("*1*",error)});  
          });
      },
      ShowConnectPaymentDlg(data)
      {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.OK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PAYMENT.QUESTION.CONNECT').replace("*1*",data.invoice.bez).replace("*2*",data.invoice.amount),
          accept: this.ConnectPayment,
          parameters:{"param":data}
        });
      },
      ShowNoInvoiceFoundDlg()
      {
        this.$vs.dialog({
          color: "primary",
          title: this.$t('COM.HINT.TITEL'),
          acceptText: this.$t('COM.OK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PAYMENT.NO_INVOICE_FOUND')
        });
      },      
      ConnectPayment(param){

        if(this.IsNotNull(param))
        {
          var data = param.param;

          data.member.forEach(mem => {
            mem.selected = true;
          });

          this.$store.dispatch('accounting/markInvoiceAsPayed',data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PAYMENT.CONNECT.SUCCESS')});  
                this.$emit('Success');
            }
            else
            {
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PAYMENT.CONNECT.WARNING')}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENT.CONNECT.ERROR').replace("*1*",error)});  
          });
        }
      }
  },
  computed:{
    formValid(){
      return !this.date_fail && this.amount_ok && this.reference_ok && this.country_fk_ok && this.currency_fk_ok;
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
    paymentTypeText(){
      var retVal = this.GetPaymentTypeText(paymentType.INCOMING);
      if(this.amount < 0)
      {
        retVal = this.GetPaymentTypeText(paymentType.OUTGOING);
      }
      return retVal;
    }
  },
  watch:{
    date_payment(value){
      if(this.IsNotNull(value))
      {
        this.date_fail = false;
      }
      else
      {
        this.date_fail = true;
      }
    },
    amount(value)
    {
       this.amount_fail = value == 0;
       this.amount_ok = !this.amount_fail;
    },
    reference(value)
    {
      this.reference_fail = value.length == 0;
      this.reference_ok = !this.reference_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    currency_fk(value){
      this.currency_fk_fail = value <= 0;
      this.currency_fk_ok = !this.currency_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddPayment > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddPayment > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>