<template>
    <div>
      <vs-prompt
      @cancel="Canceld"
      @accept="Accepted"
      @close="Closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="AddDebitorContactEventDlg"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('DEBITORCONTACT.PROMPT.HEADER.TEXT')}}</p>
          <vs-divider>{{$t('DEBITORCONTACT.DIVIDER.DATA')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('DEBITORCONTACT.LABEL.BEZ')"  class="float-left mr-3 inputx" :placeholder="$t('DEBITORCONTACT.LABEL.PLACEHOLDER.BEZ')"  v-model="bez"  :danger="bez_fail" val-icon-danger="clear" />
            <DatePicker Type="datetime" class="float-left" :Label="$t('DEBITORCONTACT.CONTACT_DATETIME')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('DEBITORCONTACT.TITLE.CONTACT_DATETIME')" v-model="contact_at"></DatePicker>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <div class="float-left mr-3" :class="reason_fail ? 'fail' : ''">
              <InputSelectList :label="$t('DEBITORCONTACT.REASON')" :type="CONTACTREASON" v-model="reason_txt"></InputSelectList>
            </div>
            <div class="float-left"  :class="contacttype_fail ? 'fail' : ''">
              <InputSelectList :label="$t('DEBITORCONTACT.TYPE')" :type="CONTACTTYPE" v-model="contacttype_txt"></InputSelectList>
            </div>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('DEBITORCONTACT.INFO_SHORT')"  class="float-left mr-3 inputx" :placeholder="$t('DEBITORCONTACT.LABEL.PLACEHOLDER.INFO_SHORT')"  v-model="info_short"  :danger="info_short_fail" val-icon-danger="clear" />
          </div>
          <vs-textarea height="200px" counter="2000" :label="$t('DEBITORCONTACT.LABEL.INFO_FULL')" :counter-danger.sync="info_full_fail" v-model="info_full" />
        </div>
       </div>
    </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import DatePicker from '../DatePicker';
import enumHelper from '../../../helper/enumHelper';
import InputSelectList from '../InputSelectList.vue';
import {inputSelectType} from '../../../helper/enumHelper';

export default {
  name: "AddDebitorContactEventDlg",
  props:[],
  components:
  {
      DatePicker,
      InputSelectList
  },
  data: function (){
     return {
      dlgActive: false,
      popupTitle: this.$t('ADD_DEBITOROBJECT_EVENT.TITLE'),
      id:0,
      contact_fk:0,
      bez:"",
      contact_at:null,
      reason_txt:"",
      contacttype_txt:"",
      info_short:"",
      info_full:"",
      reason_fail:false,
      reason_ok:false,
      contacttype_fail:false,
      contacttype_ok:false,
      info_short_fail:false,
      info_short_ok:false,
      info_full_fail:false,
      bez_fail:false,
      bez_ok:false,
      CONTACTREASON: inputSelectType.CONTACTREASON,
      CONTACTTYPE: inputSelectType.CONTACTTYPE
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(contact_fk){
        this.ClearForm();
        this.contact_fk = contact_fk;
        this.dlgActive = true;
      },
      Closed(){
        this.ClearForm();
      },
      InitDlgData(id){
        
        if(this.IsNotNull(id))
        {
          this.popupTitle = this.$t('ADD_DEBITOROBJECT_EVENT.TITLE.EDIT');
          this.LoadEventById(id);
          
          this.dlgActive=true;
          
        }
      },
      Accepted(){

         var name = this.bez;

          var data = {
            id:this.id,
            bez:this.bez,
            contact_at:this.contact_at,
            reason_txt:this.reason_txt,
            contacttype_txt:this.contacttype_txt,
            info_short:this.info_short,
            info_full:this.info_full,
            contact_fk:this.contact_fk
          }

          this.$store.dispatch('debitorcontact/saveDebitorContactMember', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_DEBITOROBJECT_EVENT.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('ADD_DEBITOROBJECT_EVENT.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted');
          }, 
          error =>{
              var text = this.$t('ADD_DEBITOROBJECT_EVENT.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      Canceld(){
        this.ClearForm();
      },
      ClearForm(){
       
        this.popupTitle = this.$t('ADD_DEBITOROBJECT_EVENT.TITLE');

        this.id = 0;
        this.contact_fk = 0;
        this.bez = "";
        this.contact_at = null;
        this.reason_txt = "";
        this.contacttype_txt = "";
        this.info_short = "";
        this.info_full = "";
        this.bez_fail =false;
        this.bez_ok = false;
        this.contacttype_fail=false;
        this.contacttype_ok = false;
        this.reason_fail = false;
        this.reason_ok = false;
        this.info_short_fail=false;
        this.info_short_ok=false;
        this.info_full_fail=false;

      },
      LoadEventById(data){

        if(this.IsNotNull(data) && this.IsDefined(data))
        {
          this.id = data.id;
          this.bez = data.bez;
          this.contact_at = data.contact_at;
          this.reason_txt = data.reason_txt;
          this.contacttype_txt = data.contacttype_txt;
          this.info_short = data.info_short;
          this.info_full = data.info_full;
          this.contact_fk = data.contact_fk;
        }
      }
  },
  computed:{
    formValid(){
        var valid = this.reason_ok && this.contacttype_ok && this.info_short_ok  && this.bez_ok && this.contact_at != null && !this.info_full_fail && this.contact_fk > 0;
        return valid;
    }
  },
  watch:{
    bez(value){

        this.bez_fail = value.length == 0;
        this.bez_ok = !this.bez_fail;
    },
    info_short(value)
    {
        this.info_short_fail = value.length == 0;
        this.info_short_ok = !this.info_short_fail;
    },
    reason_txt(value)
    {
        this.reason_fail = value.length == 0;
        this.reason_ok = !this.reason_fail;
    },
    contacttype_txt(value)
    {
        this.contacttype_fail = value.length == 0;
        this.contacttype_ok = !this.contacttype_fail;
    }
    
  }
};
</script>
<style>
div#AddDebitorContactEventDlg > div.vs-dialog{
  max-width: 800px !important;
}
.fail{
  border: 1px solid red;
  border-radius: 3px;
}
</style>