<template>    
  <vs-tooltip
    :title="$t('COM.SENT_MAIL_HEADER')"
    color="gray"
    :text="GetToolTipText">
    <vs-chip color="primary">
      <vs-avatar icon="markunread" color="black"/>{{$t('ENUMS.COMMONSTATE.SENT')}}
    </vs-chip>
  </vs-tooltip>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';

export default {
  name: "MailSentChip",
  props:['MailSentInfo'],
    data: function (){
     return {
      mailsentinfo:this.MailSentInfo
    }
  }, 
  computed:{
    GetToolTipText()
    {
      if(this.IsNotNull(this.mailsentinfo))
      {
        let txt = "";
        if(this.IsTextEmpty(this.mailsentinfo.sent_to_mail1) == false)
        {
          txt = this.mailsentinfo.debitorname + ", " + this.DateToString(this.mailsentinfo.sent_at,false) + " "+this.mailsentinfo.sent_to_mail1;
        }
        else if(this.IsTextEmpty(this.mailsentinfo.sent_to_mail2) == false)
        {
          txt = this.mailsentinfo.debitorname + ", " + this.DateToString(this.mailsentinfo.sent_at,false) + " "+this.mailsentinfo.sent_to_mail2;
        }

        if(this.IsTextEmpty(this.mailsentinfo.sent_to_mail_internal) == false)
        {
          if(this.IsTextEmpty(txt) == false)
          {
            txt += ', ';
          }
          txt += this.$t('MESSAGES.SEND_DOCUMENT_MAIL.MAIL_INTERN') + ': ' + this.mailsentinfo.sent_to_mail_internal;
        }
        
        return txt;
      }
      else
      {
        return "";
      }
      
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    
  }
};
</script>