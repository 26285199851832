<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddOrder"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ORDER.ADD_ORDER.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">{{$t('ORDER.ADD_ORDER.ORDER_NUMBERGROUP')}} 
            <vs-select :disabled="order_nr.length > 0" v-model="numbergroup_fk" class="ml-2" :danger="numbergroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mt-2">{{$t('ORDER.ADD_ORDER.ORDER_NUMBERGROUP_AB')}} 
            <vs-select :disabled="order_nr.length > 0" v-model="numbergroup_AB_fk" class="ml-2" :danger="numbergroup_AB_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups_AB" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input ref="bez" :label="$t('ORDER.ADD_ORDER.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('ORDER.ADD_ORDER.LABEL.PLACEHOLDER.BEZ')"  v-model="bez" :danger="bez_fail" val-icon-danger="clear"/>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "AddOrderDlg",
  props:['InitData'],
  data: function (){
     return {
       dlgActive:false,
       popupTitle:this.$t('ORDER.ADD_ORDER.TITLE'),
       order_nr:"",
       numbergroup_fk:-1,
       numbergroup_fk_fail:false,
       numbergroup_fk_ok:false,
       numbergroup_AB_fk:-1,
       numbergroup_AB_fk_fail:false,
       numbergroup_AB_fk_ok:false,       
       numbergroups:[],
       bez:"",
       bez_fail:false,
       bez_ok:false,
       offer_fk:0,
       offer_nr:"",
       defaultNrGrpFk:-1,
       defaultNrGrpABFk:-1,
       numbergroups_AB:[]
     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(offer_nr,offer_fk){
        this.ClearForm();

        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }

        if(this.defaultNrGrpABFk != -1)
        {
          this.numbergroup_AB_fk = this.defaultNrGrpABFk;
        }

        this.bez = this.$t('ORDER.ADD_ORDER.ORDER_FOR_OFFER') + ' ' + offer_nr;
        this.offer_fk = offer_fk;
        this.offer_nr = offer_nr;
        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

          var order = {
            bez:this.bez,
            numbergroup_fk:this.numbergroup_fk,
            offer_fk:this.offer_fk,
            numbergroup_AB_fk:this.numbergroup_AB_fk
          };

          var name = this.offer_nr;
          this.$store.dispatch('accounting/createOrderForOffer', order)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.ADD_ORDER.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('ORDER.ADD_ORDER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
              this.$emit('Fail');
            }
            
          }, 
          error =>{
              var text = this.$t('ORDER.ADD_ORDER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Fail');
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.order_nr = "";
        this.numbergroup_fk = -1;
        this.numbergroup_AB_fk = -1;
        this.bez = "";
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.bez_fail = false;
        this.bez_ok = false;
        this.offer_fk = 0;
        this.numbergroup_AB_fk_fail = false;
        this.numbergroup_AB_fk_ok = false;
      }
  },
  computed:{
    formValid(){
      return this.bez_ok && this.numbergroup_fk_ok && this.numbergroup_AB_fk_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.AUFTRAG);
    },
    numbergroupsABForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.AB);
    }
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    numbergroupsABForSelect(value)
    {
        this.numbergroups_AB = value.numbergroups;
        this.defaultNrGrpABFk = value.default;
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
    numbergroup_AB_fk(value){
      this.numbergroup_AB_fk_fail = value <= 0;
      this.numbergroup_AB_fk_ok = !this.numbergroup_AB_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddOrder > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddOrder > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>