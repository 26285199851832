<template>
  <div id="orders_table" class="vs-con-loading__container" >
  <vs-table
    search
    :data="orders"
    :noDataText="$t('ORDER.NO_DATA')"
    stripe
    max-items="50" 
    pagination
    hoverFlat>

    <template slot="header">
      <h3>
        {{$t('ORDER.TABLE.HEADER')}}
      </h3>
    </template>
    <template slot="thead">                               
      <vs-th sort-key="bez">
        {{$t('ORDER.TABLE.COL1')}}
      </vs-th>
      <vs-th sort-key="order_nr">
        {{$t('ORDER.TABLE.COL2')}}
      </vs-th>                         
      <vs-th sort-key="last_change">
        {{$t('ORDER.TABLE.COL4')}}
      </vs-th>  
      <vs-th sort-key="state">
        {{$t('ORDER.TABLE.COL5')}}
      </vs-th>
      <vs-th sort-key="created_by_str">
        {{$t('ORDER.TABLE.COL6')}}
      </vs-th>                      
      <vs-th></vs-th>                                                                                                                                             
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
        <vs-td :data="tr.bez">
          {{tr.bez}}
        </vs-td>      
        <vs-td :data="tr.order_nr">
          {{tr.order_nr}}
        </vs-td>                     
        <vs-td :data="tr.last_change">
          {{DateToString(tr.last_change,false)}}
        </vs-td>                   
        <vs-td :data="tr.state">
          <vs-chip :color="GetCommonStateColor(tr.state)">
            {{GetCommonStateText(tr.state)}}</vs-chip>
        </vs-td>  
        <vs-td :data="tr.created_by_str">
          {{tr.created_by_str}}
        </vs-td>                   
        <vs-td>
          <div class="d-flex">
            <PollingButtonDoc :key="'polling'+tr.id" :DocumentType="docType" :InitDocId="tr.document_fk" :LinkedFk="tr.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" ref="OrderPollingBtn" v-on:clicked="DownloadOrder"></PollingButtonDoc>
            <vs-button @click="PrintOrder(tr)" class="mr-1" size="small" color="primary" type="filled" icon="print" :disabled="true"></vs-button>  
            <vs-button @click="ShowOrder(tr)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>  
          </div>
        </vs-td>                    
                                                                                                                                                                                  
      </vs-tr>
    </template>
  </vs-table>
  
</div> 
</template>
<script>
import enumHelper from '../../../helper/enumHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import helperMethods from '../../../helper/staticFuncHelper';
import downloadMethods from '../../../helper/staticDownloadFuncHelper';
import {documentType} from "../../../helper/enumHelper";
import PollingButtonDoc from "../PollingButtonDoc.vue";

export default {
  name: "OrderTable",
  props:['DebitorContactFk'],
  components:
  {
    PollingButtonDoc
  },
   data: function (){
     return {
       orders:[],
       docType:documentType.ORDER
     }
  },
  created(){
    this.RefreshData(this.DebitorContactFk);
  },
  computed:{
    getOrderTableData() {
      var data = [];
      if(this.$store.state.accounting.orders.data != null)
      {
        data = this.$store.state.accounting.orders.data;
      }
      return data;
    },
    loadingOrderTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.orders.status) != 'undefined')
        loading = this.$store.state.accounting.orders.status.loading;
      return loading;
    }
  },
  methods:{ 
    ...codeHelper,
    ...helperMethods,
    ...enumHelper,
    ...downloadMethods,
    RefreshData(debcontact_fk){
      var dat = {debitorcontact_fk: debcontact_fk};
      this.$store.dispatch('accounting/getOrders',dat ); 
    },
    ShowOrder(data)
    {
      this.$emit('ShowOrder',data);
    },
    PrintOrder(data)
    {
       var name = data.bez;
            this.$store.dispatch('document/createDocument', {id:data.id,name:data.order_nr, type: documentType.AB})
              .then(success => {
                if(success === true)
                {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.PRINT').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
    },
    DownloadOrder(actDocId)
    {
      if(this.IsNotNull(actDocId))
      {
          this.$store.dispatch('document/downloadDocument', actDocId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
      }
    }
  },
  watch:{
    getOrderTableData(value)
    {
      this.orders = value;
    },
    loadingOrderTableData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#orders_table'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#orders_table'});
      }
    }
  }
};
</script>
