<template>
    <div>
    <vs-popup
      :active.sync="dlgActive"
      :title="popupTitle"
      id="popupInvoiceReminderDlg">

            <div class="d-flex align-items-center">
              <vs-button class="mt-2 mb-2"  type="filled" icon="add" @click="AddReminder()">{{$t('INVOICE_REMINDER.ADD')}}</vs-button>
            </div> 

      <div id="invoicereminder_table" class="vs-con-loading__container" >
    
        <vs-table
          search
          :data="reminder"
          :noDataText="$t('INVOICE_REMINDER.NO_DATA')"
          stripe
          max-items="5" 
          pagination
          hoverFlat>

          <template slot="header">
            <h3>
              {{$t('INVOICE_REMINDER.TABLE.HEADER')}}
            </h3>
          </template>
          <template slot="thead"> 
            <vs-th sort-key="bez">
              {{$t('INVOICE_REMINDER.TABLE.COL1')}}
            </vs-th>                              
            <vs-th sort-key="level">
              {{$t('INVOICE_REMINDER.TABLE.COL2')}}
            </vs-th>
            <vs-th sort-key="paymentDueDate">
              {{$t('INVOICE_REMINDER.TABLE.COL3')}}
            </vs-th> 
            <vs-th sort-key="spesen">
              {{$t('INVOICE_REMINDER.TABLE.COL4')}}
            </vs-th> 
            <vs-th sort-key="verzug_percent">
              {{$t('INVOICE_REMINDER.TABLE.COL5')}}
            </vs-th>                                    

            <vs-th sort-key="state">
              {{$t('INVOICE_REMINDER.TABLE.COL7')}}
            </vs-th>
            <vs-th sort-key="created_by_str">
              {{$t('INVOICE_REMINDER.TABLE.COL8')}}
            </vs-th>                           
            <vs-th></vs-th>                                                                                                                                             
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" > 
              <vs-td :data="tr.bez">
                {{tr.bez}}
              </vs-td>                                  
              <vs-td :data="tr.level">
                {{tr.level}}
              </vs-td>      
              <vs-td :data="tr.paymentDueDate">
                {{DateToString(tr.paymentDueDate,true)}}
              </vs-td>
              <vs-td :data="tr.spesen">
                {{GetCurrencyString(tr.spesen)}}
              </vs-td>   

              <vs-td :data="tr.verzug_percent">
                {{tr.verzug_percent}}% ({{GetCurrencyString(tr.verzug_value)}})
              </vs-td> 
                                            
              <vs-td :data="tr.state">
                <vs-chip :color="GetCommonStateColor(tr.state)">
                  {{GetCommonStateText(tr.state)}}</vs-chip>
                  <MailSentChip :key="'chip'+tr.id" v-if="IsNotNull(tr.mailSentInfo)" :MailSentInfo="tr.mailSentInfo"></MailSentChip>
              </vs-td>  
              <vs-td :data="tr.created_by_str">
                {{tr.created_by_str}}
              </vs-td>
              <vs-td >
                <div class="d-flex"> 
                  <PollingButtonDoc  :key="'polling'+tr.id" :DocumentType="docType" :InitDocId="tr.document_fk" :LinkedFk="tr.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" ref="ReminderPollingBtn" v-on:clicked="DownloadReminder"></PollingButtonDoc>
                  <SendDebitorDocuemntMailBtn :key="'mailbtn'+tr.id" class="mr-1" :doc_nr="'M_'+ tr.level" :doc_id="tr.id" :doc_type="docType"></SendDebitorDocuemntMailBtn>
                  <ButtonList :key="'btnlist'+tr.id" :buttonArray="[{label:$t('INVOICE_REMINDER.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true, disabled:false,data:[tr]},
                            {label:$t('INVOICE_REMINDER.BTN.EDIT'),color:'primary',type:'filled',icon:'edit',show:true,disabled:false,data:[tr]},
                            {label:$t('INVOICE_REMINDER.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show: $can('delete', 'invoice'),disabled:false,data:[tr.id,tr.bez]}
                          ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
                </div>
              </vs-td>                                                                                                                                                                       
            </vs-tr>
          </template>
        </vs-table>

      </div>       
    </vs-popup>
    <AddInvoiceReminderDlg ref="addInvoiceReminderDlg" v-on:Success="RefreshReminder()"></AddInvoiceReminderDlg>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import downloadMethods from '../../../helper/staticDownloadFuncHelper';
import AddInvoiceReminderDlg from "./AddInvoiceReminderDlg.vue";
import SendDebitorDocuemntMailBtn from "./SendDebitorDocuemntMailBtn.vue";
import PollingButtonDoc from "../PollingButtonDoc.vue";
import {documentType} from "../../../helper/enumHelper";
import ButtonList from "../ButtonList.vue";

export default {
  name: "InvoiceReminderDlg",
  props:[],
  components:{
    AddInvoiceReminderDlg,
    PollingButtonDoc,
    SendDebitorDocuemntMailBtn,
    ButtonList
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('INVOICE_REMINDER.INVOICE_REMINDER.TITLE'),
      invoice:null,
      docType:documentType.INVOICEREMINDER,
      reminder:[]
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ...downloadMethods,
      ShowDlg(invoice){
        this.dlgActive=true;
        this.invoice = invoice;
        this.RefreshReminder();
      },          
      GetCurrencyString(amount){
        return this.DecimalToString(amount,this.invoice.currency_kuerzel,this.GetLocalFormatOfLanguage(this.invoice.language_fk));
      },
      RefreshReminder()
      {
          this.$store.dispatch('accounting/getInvoiceReminderForInvoice',this.invoice.id ); 
      },
      AddReminder()
      {
        this.$refs.addInvoiceReminderDlg.ShowDlg(this.invoice);
      },
      EditReminder(data)
      {
        this.$refs.addInvoiceReminderDlg.InitDlgData(this.invoice, data);
      },      
      ButtonListClicked(index, data){
        switch(index)
        {
          case 0:
            this.PrintReminder(data[0])
            break;
          case 1:
            this.EditReminder(data[0])
            break;
          case 2:
            this.OpenConfirmDeleteReminder(data[0],data[1])
            break;                                              
        }
        
      },      
      DownloadReminder(actDocId)
      {
        if(this.IsNotNull(actDocId))
        {
            this.$store.dispatch('document/downloadDocument', actDocId)
                .then(response => {
                    this.DownloadWithFileSaver(response);
                  }
                );
        }
      },
      PrintReminder(data)
      {
          var type = documentType.INVOICEREMINDER;
          var name = data.bez;

              this.$store.dispatch('document/createDocument', {id:data.id,name:name, type: type})
                .then(success => {
                  if(success === true)
                  {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE_REMINDER.SUCCESS.PRINT').replace("*1*",name)});
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE_REMINDER.ERROR.PRINT').replace("*1*",name)});
                  }

                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE_REMINDER.ERROR.PRINT').replace("*1*",name)+' '+error});
                }); 
      },  
      OpenConfirmDeleteReminder(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('INVOICE_REMINDER.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('accounting/deleteInvoiceReminder', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.RefreshReminder();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE_REMINDER.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('INVOICE_REMINDER.WARNING.DELETE').replace("*1*",parameters.name)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE_REMINDER.ERROR.DELETE').replace("*1*",error)});  
        });
      }          
  },
  computed:{
    getTableData() {
      var data = [];
      if(this.$store.state.accounting.invoiceReminder.data != null)
      {
        data = this.$store.state.accounting.invoiceReminder.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.invoiceReminder.status) != 'undefined')
        loading = this.$store.state.accounting.invoiceReminder.status.loading;
      return loading;
    },
  },
  watch:{
      getTableData(value) {
          this.reminder = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#invoicereminder_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#invoicereminder_table'});
        }
      }    
  }
};
</script>
<style>
#popupInvoiceReminderDlg > .vs-popup{
  width:850px !important;
}
</style>
