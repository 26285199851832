<template>
  <div id="attachments" class="vs-con-loading__container" v-if="allAttachments.length > 0">
    
    <vs-tabs v-model="tabindex" id="attachmentTabs">
      
    <template v-for="(att, index) in allAttachments">
      <vs-tab :key="'att_'+index" :label="att.name + att.extension">
        <vs-card>
          <vs-row vs-justify="center">
            <img v-bind:src="'data:image/jpeg;base64,'+att.preview" />
          </vs-row>
          <vs-row vs-justify="center" class="mt-2" v-show="edit == false">
            <div v-show="edit == false"><p >{{att.description}}</p></div>
          </vs-row>
          <vs-row vs-justify="center" class="mt-2" v-show="edit == false">
            <vs-button class="ml-1" size="small" icon="open_in_new" @click="ViewAttachment(att)" :disabled="!att.isViewerAllowed"></vs-button>
            <vs-button class="ml-1" size="small" icon="get_app" @click="DownloadAttachment(att.id)"></vs-button>
            <vs-button class="ml-1" size="small" icon="edit" @click="edit=true"></vs-button>
            <vs-button class="ml-1" size="small" icon="delete" @click="OpenConfirmDeleteAttachment(att.id, att.name+ att.extension)"></vs-button>
          </vs-row>
          <vs-row vs-justify="center" class="mt-2" v-show="edit == true"> 
            <div class="d-flex">
              <vs-input  class="inputx" :placeholder="$t('DEBITORCONTACT.EDIT.ATTACH.DESCR')"  v-model="att.description" />   
              
              <vs-button class="ml-1" size="small" icon="save" @click="updateAttachment(att)"></vs-button>
              <vs-button class="ml-1" size="small" icon="cancel" @click="edit = false"></vs-button>
            </div>
          </vs-row>
        </vs-card>

      </vs-tab>
     
    </template>
    </vs-tabs>
    <vs-row vs-justify="center">
      <vs-pagination v-show="allAttachments.length > 0" :total="allAttachments.length" v-model="pageindex"></vs-pagination>
    </vs-row>
    <vs-popup fullscreen :title="$t('DEBITORCONTACT.POPUP_ATTACHMENT')" :active.sync="imgPopupActive">
            <img v-bind:src="'data:image/jpeg;base64,'+this.imgFull" />
    </vs-popup>
  </div>
</template>

<script>

import codeHelper from '../../helper/staticCodeHelper';
import downloadMethods from '../../helper/staticDownloadFuncHelper';

export default {
  name: "ObjectAttachments",
  props:['ObjectId'],
   data: function (){
     return {
      allAttachments:[],
      tabindex:0,
      edit:false,
      pageindex:1,
      imgPopupActive:false,
      imgFull:""
     }
  },
  computed:{
    attachments() {
      var data = [];
      if(this.$store.state.debitorobject.attachments.data != null)
      {
        data = this.$store.state.debitorobject.attachments.data
      }
      return data;
    },
    loadingAttachments () {
      var loading = false;
      if(typeof(this.$store.state.debitorobject.attachments.status) != 'undefined')
        loading = this.$store.state.debitorobject.attachments.status.loading;
      return loading;
    },
  },
  methods:{
    ...codeHelper,
    ...downloadMethods,
    updateAttachment(att){
      var name = att.name + att.extension;
      this.$store.dispatch('debitorobject/updateDebitorObjectAttachment',att)
      .then(response => {
        if(response.success == true)
        {
          this.LoadAttachments();
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.SUCCESS.EDIT').replace("*1*",name)});  
        }
        else
        {
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.WARNING.EDIT').replace("*1*",name)}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.ERROR.EDIT').replace("*1*",error)});  
      });
      this.edit = false;
    },
    DownloadAttachment(attId)
    {
      this.$store.dispatch('debitorobject/downloadPdfAttachment', attId)
        .then(response => {
            this.DownloadWithFileSaver(response);
          }
        );
    },
    OpenConfirmDeleteAttachment(id,name) {
      this.$vs.dialog({
      type: "confirm",
      color: "primary",
      title: this.$t('COM.SURE.TITEL'),
      acceptText: this.$t('COM.DELETE'),
      cancelText:this.$t('COM.CANCEL'),
      text: this.$t('DEBITORCONTACT.ATTACHMENT.QUESTION.DELETE').replace("*1*",name),
      accept: this.SendDeleteAttchmentRequest,
      parameters:{"id": id,"name": name}
      });

    },
    SendDeleteAttchmentRequest: function(parameters)
    {
      this.$store.dispatch('debitorobject/deleteDebitorObjectAttachment', parameters.id)
      .then(response => {
        if(response.success == true)
        {
          this.LoadAttachments();
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
        }
        else
        {
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.WARNING.DELETE').replace("*1*",parameters.name)}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ATTACHMENT.ERROR.DELETE').replace("*1*",error)});  
      });
    },
    LoadAttachments(){
      this.$store.dispatch('debitorobject/getDebitorObjectAttachments',this.ObjectId);
    },
    ViewAttachment(att){
      if(att.isImg){
        this.ViewAttachmentImg(att.id);
      }
      else
      {
        this.ViewAttachmentPdf(att.id);
      }
    },
    ViewAttachmentPdf(attId){
      
     this.$emit("ShowPdf",attId);

    },
    ViewAttachmentImg(attId){
        this.$store.dispatch('debitorobject/downloadPdfAttachment', attId)
          .then(response => {
              this.imgFull = response.data;
              this.imgPopupActive = true;
            }
          );
    }
  },
  watch:{
   attachments(value) {
        this.allAttachments = value;
    },
    loadingAttachments(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#attachments'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#attachments'});
      }
    },
    tabindex(value){
      this.pageindex = value+1;
    },
    pageindex(value){
      this.tabindex = value -1;
    },
  }
};
</script>
<style>
div#attachmentTabs > .con-ul-tabs{
  display: none;
}
</style>