var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-con-loading__container",attrs:{"id":"invoice_table"}},[_c('vs-table',{attrs:{"search":"","data":_vm.invoices,"noDataText":_vm.$t('INVOICE.NO_DATA'),"stripe":"","max-items":"50","pagination":"","hoverFlat":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.bez}},[_vm._v(" "+_vm._s(tr.bez)+" ")]),_c('vs-td',{attrs:{"data":tr.invoice_nr}},[_vm._v(" "+_vm._s(tr.invoice_nr)+" ")]),_c('vs-td',{attrs:{"data":tr.type}},[_vm._v(" "+_vm._s(_vm.GetInvoiceTypeText(tr.type))+" ")]),_c('vs-td',{attrs:{"data":tr.last_change}},[_vm._v(" "+_vm._s(_vm.DateToString(tr.last_change,false))+" ")]),_c('vs-td',{attrs:{"data":tr.state}},[_c('vs-chip',{attrs:{"color":_vm.GetCommonStateColor(tr.state)}},[_vm._v(" "+_vm._s(_vm.GetCommonStateText(tr.state)))]),(_vm.IsNotNull(tr.mailSentInfo))?_c('MailSentChip',{key:'chip'+tr.id,attrs:{"MailSentInfo":tr.mailSentInfo}}):_vm._e()],1),_c('vs-td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invoiceForOrder),expression:"!invoiceForOrder"}],attrs:{"data":tr.created_by_str}},[_vm._v(" "+_vm._s(tr.created_by_str)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.GetInvoiceSumOffen(tr))+" / "),_c('h4',[_vm._v(_vm._s(_vm.GetInvoiceSum(tr)))])]),_c('vs-td',[_c('div',{staticClass:"d-flex"},[_c('PollingButtonDoc',{key:'polling'+tr.id,ref:"InvoicePollingBtn",refInFor:true,attrs:{"DocumentType":_vm.docType,"InitDocId":tr.document_fk,"LinkedFk":tr.id,"Icon":"get_app","Size":"small","Color":"primary","Type":"filled","ButtonClass":"mr-1"},on:{"clicked":_vm.DownloadInvoice}}),_c('SendDebitorDocuemntMailBtn',{key:'mailbtn'+tr.id,staticClass:"mr-1",attrs:{"doc_nr":tr.invoice_nr,"doc_id":tr.id,"doc_type":_vm.docType}}),_c('ButtonList',{key:'btnlist'+tr.id,attrs:{"buttonArray":[{label:_vm.$t('INVOICE.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true, disabled:false,data:[tr,indextr]},
                                       {label:_vm.$t('INVOICE.BTN.SHOW'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:tr.document_fk <= 0,data:[tr.document_fk]},
                                       {label:_vm.$t('INVOICE.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show: _vm.$can('delete', 'invoice'),disabled:_vm.isDisabled,data:[tr.id,tr.invoice_nr]},
                                       {label:_vm.$t('INVOICE.BTN.STORNO'),color:'primary',type:'filled',icon:'clear',show:true,disabled:_vm.isDisabled,data:[tr.id,tr.invoice_nr]},
                                       {label:_vm.$t('INVOICE.BTN.PAYMENT'),color:'primary',type:'filled',icon:'euro',show:true,disabled:_vm.paymentNotAllowed(tr),data:[tr]},
                                       {label:_vm.$t('INVOICE.BTN.INVOICE_REMINDER'),color:'primary',type:'filled',icon:'report',show:true,disabled:false,data:[tr]}
                                      ]},on:{"BtnClicked":_vm.ButtonListClicked}})],1)]),_c('vs-td',{directives:[{name:"show",rawName:"v-show",value:(_vm.invoiceForOrder),expression:"invoiceForOrder"}]},[_c('vs-button',{directives:[{name:"show",rawName:"v-show",value:(tr.isForeign),expression:"tr.isForeign"}],attrs:{"disabled":_vm.isDisabled,"size":"small","color":"primary","type":"filled","icon":"remove"},on:{"click":function($event){return _vm.RemoveInvoiceFromOrder(tr)}}})],1)],1)})}}])},[_c('template',{slot:"header"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.HEADER'))+" ")])]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"bez"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL1'))+" ")]),_c('vs-th',{attrs:{"sort-key":"invoice_nr"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL2'))+" ")]),_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL7'))+" ")]),_c('vs-th',{attrs:{"sort-key":"last_change"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL4'))+" ")]),_c('vs-th',{attrs:{"sort-key":"state"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL5'))+" ")]),_c('vs-th',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invoiceForOrder),expression:"!invoiceForOrder"}],attrs:{"sort-key":"created_by_str"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL6'))+" ")]),_c('vs-th',{attrs:{"sort-key":"betrag"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL8'))+" ")]),_c('vs-th')],1)],2),_c('AddPaymentDlg',{ref:"addPaymentDlg",on:{"Success":_vm.PaymentAdded}}),_c('vs-popup',{attrs:{"fullscreen":"","title":_vm.$t('INVOICE.POPUP_INVOICE'),"active":_vm.pdfPopupActive},on:{"update:active":function($event){_vm.pdfPopupActive=$event}}},[_c('PdfJsViewer',{ref:"pdfViewer",attrs:{"fileName":""}})],1),_c('InvoiceReminderDlg',{ref:"invoiceReminderDlg"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }